<template>
  <div>
    <div slot="header" class="clearfix">

      <div style="margin-bottom: 10px" v-if="isA" key="18">
        <div style="margin: 10px 0;padding-bottom: 10px">
          <el-button type="primary" plain @click="openAddDialog" style="width: 120px;">新增</el-button>
          <el-button type="danger" plain @click="delbatch" :loading="isloading2">批量删除</el-button>
          <el-button type="info" plain @click="reset">刷新</el-button>
        </div>
<!--        <el-input style="width: 200px;" placeholder="查询用户" v-model="username"></el-input>-->
        <el-autocomplete
            style="width: 200px;"
            v-model="username"
            placeholder="查询用户"
            :fetch-suggestions="querySearch"

        ></el-autocomplete>
        <el-input style="width: 200px; margin: 0 10px" placeholder="项目名称" v-model="projectName"></el-input>
        <el-input style="width: 200px;" placeholder="广告位ID" v-model="appid"></el-input>
        <el-button style="margin-left: 10px" type="primary" @click="load(1)">查询</el-button>
        <el-button type="info" @click="reset">重置</el-button>

      </div>
<!--      <span class="yh">项目列表</span>-->
      <el-table :data="projects" stripe :header-cell-style="{backgroundColor:'#dae9f8' ,font:'bold'}"
                style="width: 100%; padding-top: 10px"
                @selection-change="handleSelectionChange" key="1">

        <el-table-column v-if="ist"  type="selection" width="50" align="center" key="2"></el-table-column>

        <el-table-column label="用户" :min-width="90" :show-overflow-tooltip="true"  align="center" v-if="ist" key="3">
          <template v-slot="scope">
            <span>{{ scope.row.username }}</span>
          </template>
        </el-table-column>

        <el-table-column label="项目名称" :min-width="120" :show-overflow-tooltip="true"  align="center" key="4">
          <template v-slot="scope">
            <span>{{ scope.row.projectName }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="isA" label="项目ID" :min-width="120" :show-overflow-tooltip="true" align="center" key="5">
          <template v-slot="scope">
            <span>{{ scope.row.originalId }}</span>
          </template>
        </el-table-column>

        <el-table-column label="广告位ID" :min-width="120" :show-overflow-tooltip="true" align="center" key="6">
          <template v-slot="scope">
            <span>{{ scope.row.appid }}</span>
          </template>
        </el-table-column>

        <el-table-column label="广告位名称" :min-width="120" :show-overflow-tooltip="true" align="center" key="7">
          <template v-slot="scope">
            <span>{{ scope.row.appidName }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="company" label="主体信息" :min-width="105" :show-overflow-tooltip="true" align="center" key="8"></el-table-column>

        <el-table-column prop="proDev" label="开发者" :min-width="105" :show-overflow-tooltip="true" v-if="isA" align="center" key="9"></el-table-column>
        <el-table-column prop="stu" label="状态" :min-width="80" :show-overflow-tooltip="true"  align="center" key="19"></el-table-column>
        <el-table-column prop="cms" label="内容管理后台" :min-width="110" :show-overflow-tooltip="true"  align="center" key="20"></el-table-column>
        <el-table-column prop="cmsId" label="内容后台账号" :min-width="120" :show-overflow-tooltip="true"  align="center" key="22"></el-table-column>
        <el-table-column prop="cmsPass" label="内容后台密码" :min-width="120" :show-overflow-tooltip="true"  align="center" key="23"></el-table-column>
        <el-table-column label="操作" :min-width="240" align="center" key="10">
          <template v-slot="scope">
            <el-button type="primary" size="mini" plain @click="edit(scope.row)" v-if="isA" key="11">编辑</el-button>
            <el-button type="primary" size="mini" plain @click="copyAddress(scope.row)" key="13">复制地址</el-button>
            <el-button type="danger" size="mini" plain @click="handleDelete(scope.row.id)" v-if="isA" key="12">删除</el-button>
          </template>
        </el-table-column>

        <el-table-column label="创建日期" :min-width="145" :show-overflow-tooltip="true" align="center" v-if="isA" key="24">
          <template v-slot="scope">
            <div style="white-space: nowrap;">
      <span>
        {{
          (() => {
            const date = new Date(scope.row.createTime);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          })()
        }}
      </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="更新日期" :min-width="145" :show-overflow-tooltip="true" align="center" key="25" v-if="isA">
          <template v-slot="scope">
            <div style="white-space: nowrap;">
      <span>
        {{
          (() => {
            const date = new Date(scope.row.updateTime);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          })()
        }}
      </span>
            </div>
          </template>
        </el-table-column>


      </el-table>

      <div style="display: flex;justify-content: center;margin-top: 20px" v-if="isA" key="31">
        <el-pagination

            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="pageSize"
            layout="total,  prev, pager, next"
            :total=total>
        </el-pagination>
      </div>

    </div>
    <div >
      <el-dialog title="新增项目" :visible.sync="formVisible"  width="30%" :close-on-click-modal="false" :before-close="qx">
        <el-form :model="form" style="width: 70%;" >
          <el-form-item label="用户" :label-width="formLabelWidth">
            <el-autocomplete  class="inline-input"
                v-model="form.username"
                :fetch-suggestions="querySearch"
                placeholder="输入或选择用户"
                >
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="项目名称" :label-width="formLabelWidth">
            <el-input v-model="form.projectName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="广告位ID" :label-width="formLabelWidth">
            <el-input v-model="form.appid" autocomplete="off"></el-input>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center; display: flex;justify-content: center;">
          <el-button @click="qx">取 消</el-button>
          <el-button type="primary" @click="handleAdd" :loading="isloading" style="width: 120px">提 交</el-button>
        </div>
      </el-dialog>
    </div>
    <div >
      <el-dialog title="编辑用户" :visible.sync="formVisible2" width="40%" :close-on-click-modal="false">
        <el-form :model="form2" style="width: 95%;" label-position="top">
          <div class="form-row">
            <el-form-item prop="username" label="用户" class="form-item">
              <el-autocomplete  class="inline-input"
                                v-model="form2.username"
                                :fetch-suggestions="querySearch"
                                placeholder="输入或选择用户"
              >
              </el-autocomplete>
            </el-form-item>
            <el-form-item prop="projectName" label="项目名称" class="form-item">
              <el-input v-model="form2.projectName" autocomplete="off"></el-input>
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item prop="originalId" label="项目ID" class="form-item">
              <el-input v-model="form2.originalId" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="appid" label="广告位ID" class="form-item">
              <el-input v-model="form2.appid" autocomplete="off"></el-input>
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item prop="company" label="主体信息" class="form-item">
              <el-input v-model="form2.company" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="proDev" label="开发者" class="form-item">
              <el-input v-model="form2.proDev" autocomplete="off"></el-input>
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item prop="stu" label="状态" class="form-item">
              <el-input v-model="form2.stu" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="cms" label="内容管理后台" class="form-item">
              <el-input v-model="form2.cms" autocomplete="off"></el-input>
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item prop="cmsId" label="内容后台账号" class="form-item">
              <el-input v-model="form2.cmsId" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="cmsPass" label="内容后台密码" class="form-item">
              <el-input v-model="form2.cmsPass" autocomplete="off"></el-input>
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item prop="appidName" label="广告类型" class="form-item">
              <el-input v-model="form2.appidName" autocomplete="off"></el-input>
            </el-form-item>

          </div>

        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center; display: flex;justify-content: center;">
          <el-button @click="formVisible2 = false">取 消</el-button>
          <el-button type="primary" @click.stop="handleEdit" :loading="isloading1" style="width: 120px">提 交</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import axios from "axios";
import { Loading } from 'element-ui';
export default {
  name: 'projects',
  data() {
    return {
      isloading:false,
      isloading1:false,
      isloading2:false,
      ist:false,
      isA:null,
      projects: [],
      project:{},
      pageNum: 1,
      pageSize: 16,
      username:"",
      appid:'',
      projectName:"",
      total:0,
      formVisible: false,
      formVisible2: false,
      form: {},
      form2: {
        appidName: "激励视频广告", // 设置默认值
      },
      formLabelWidth: '80px',
      originalUser: {},  // 存储初始数据，用于取消时恢复
      ids:[],
      formUsers:[],
      restaurants: [],
      lu:[],
      currentUser:{},
      currentUserName:'',
      role1:2,
    }
  },
  mounted() {
    this.loadUser()
    this.currentUser = JSON.parse(localStorage.getItem('honey-user'));
    this.currentUserName =this.currentUser.username;
    this.role1 =this.currentUser.role;
    if (this.role1 === 1) {
      this.load()
      this.isA = true

    }else {
      this.load2()
    }

  },
  methods: {
    showLoading() {
      this.loadingInstance = Loading.service({
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
    },

    // 隐藏加载弹窗
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },


    copyAddress(row) {
      const address = row.cms;

      // 检查地址是否为空
      if (!address) {
        this.$message.error('地址为空');
        return; // 提前结束函数
      }

      // 创建临时 textarea 元素用于复制
      const textArea = document.createElement('textarea');
      textArea.value = address;
      document.body.appendChild(textArea);

      // 选中内容并复制
      textArea.select();
      const success = document.execCommand('copy');
      document.body.removeChild(textArea);

      // 根据复制是否成功显示对应提示
      if (success) {
        this.$message.success('内容管理后台地址已复制到剪贴板！');
      } else {
        this.$message.error('复制失败，请重试！');
      }
    },

    delbatch(){
      if(!this.ids.length){
        this.$message.warning('请选择要删除的数据')
        return
      }

      this.$confirm('是否要批量删除?','确认删除',{type:"warning"}).then(response => {
        this.isloading2=true
        // this.showLoading(); // 显示加载弹窗
        this.$request.delete('/projects/delete/batch',{data:this.ids}).then((res1) => {
          if (res1.code === '200') {
            this.$message.success('删除成功');
            this.isloading2=false
            // localStorage.setItem('honey-user', JSON.stringify(this.user));
            // this.formVisible2 = false; // 关闭模态框
            // this.hideLoading(); // 隐藏加载弹窗
            this.load()
          }else {
            this.$message.error(res1.mag)
            this.isloading2=false
            // this.hideLoading(); // 隐藏加载弹窗
          }
        })
      }).catch((error) => {})
      // this.hideLoading(); // 隐藏加载弹窗
    },
    handleDelete(id){
      // console.log('Deleting project with ID:', id);
      this.$confirm('是否要删除?','确认删除',{type:"warning"}).then(response => {
        this.showLoading(); // 显示加载弹窗
        this.$request.delete('/projects/delete/'+id).then((res2) => {

          if (res2.code === '200') {
            this.$message.success('删除成功');
            // localStorage.setItem('honey-user', JSON.stringify(this.user));
            // this.formVisible2 = false; // 关闭模态框
            this.hideLoading(); // 隐藏加载弹窗
            this.load()
          }else {
            this.$message.error(res2.mag)
            this.hideLoading(); // 隐藏加载弹窗
          }
        })
      }).catch((error) => {})
    },
    querySearch(queryString, cb) {
      // 假设你有一个用户数组，你可以根据输入的内容过滤用户

      const results = queryString ? this.formUsers.filter(this.createFilter(queryString)) : this.formUsers;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    qx(){
      this.formVisible = false
      this.username = "";
      this.projectName ="";
      // this.form2={};
      this. appid=''
      this.form={};
    },
    handleAdd(){
      this.isloading=true
      this.project = this.form;
      // this.showLoading(); // 显示加载弹窗
      this.$request.post('/projects/add', this.project).then((res3) => {
        if (res3.code === '200') {
          this.isloading=false
          this.$message.success('添加成功');
          // localStorage.setItem('honey-user', JSON.stringify(this.user));
          this.formVisible = false; // 关闭模态框
          // this.hideLoading(); // 隐藏加载弹窗
          // this.load()
          this.reset()
        }else {
          this.$message.error(res3.msg)
          this.isloading=false
          // this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(error => {
        this.isloading=false
        // this.hideLoading(); // 隐藏加载弹窗
        console.error(error); // 处理错误情况

      });
    },
    edit(row){
      this.form2 = JSON.parse(JSON.stringify(row));
      this.formVisible2=true; // 打开模态框

      // console.log('this.form2 is :')
      // console.log(this.form2);

    },
    loadUser(){

      this.$request.get('/user/selectAll').then((result) => {
        this.lu = result.data
        this.formUsers = this.lu.map(lu=> ({ value: lu.username}));
        this.hideLoading(); // 隐藏加载弹窗
      }).catch(error => {
        console.error(error); // 处理错误情况
        this.$router.push('/login'); // 可能需要在请求失败时重定向

      });
    },
    load2(pageNum) {
      this.showLoading(); // 显示加载弹窗
      if (pageNum) {
        this.pageNum = pageNum;
      }

      this.$request.get('/projects/selectByPage', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          username: this.currentUserName,
          appid: this. appid,
          projectName:this.projectName,
        }
      }).then(res4 => {
        if (res4.data && res4.data.records) {
          this.projects = res4.data.records;
          this.total = res4.data.total;
          // console.log('this.projects is:')
          // console.log(this.projects)
          this.hideLoading(); // 隐藏加载弹窗
        } else {
          this.projects = [];
          this.total = 0; // 或者其他处理逻辑
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    load(pageNum) {
      this.showLoading(); // 显示加载弹窗
      if (pageNum) {
        this.pageNum = pageNum;
      }

      this.$request.get('/projects/selectByPage', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          username: this.username,
          appid: this. appid,
          projectName:this.projectName,
        }
      }).then(res5 => {
        if (res5.data && res5.data.records) {
          this.projects = res5.data.records;
          this.total = res5.data.total;
          this.ist = true
          // console.log('this.projects is:')
          // console.log(this.projects)
          this.hideLoading(); // 隐藏加载弹窗
        } else {
          this.hideLoading(); // 隐藏加载弹窗
          this.projects = [];
          this.total = 0; // 或者其他处理逻辑
        }
      }).catch(err2 => {
        this.hideLoading(); // 隐藏加载弹窗
        console.error(err2); // 处理错误
      });
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      if (this.role1 === 1) {
        this.load()
        // console.log('this begin load')
        this.isA = true

      }else {
        this.load2()

        this.isA = null

      }
    },
    handleSelectionChange(rows){
      this.ids = rows.map(item => item.id);
    },
    openAddDialog(){
      this.formVisible=true;
    },
    handleEdit(){
      this.isloading1=true
      this.project = this.form2;
      // this.showLoading(); // 显示加载弹窗
      this.$request.put('/projects/update', this.project).then((res7) => {
        if (res7.code === '200') {
          this.$message.success('修改成功');
          this.isloading1=false
          // this.hideLoading(); // 隐藏加载弹窗
          // localStorage.setItem('honey-user', JSON.stringify(this.user));
          this.formVisible2 = false; // 关闭模态框
          this.reset()
        }else {
          // this.hideLoading(); // 隐藏加载弹窗
          this.$message.error(res7.msg)
        }
      }).catch(error => {
        console.error(error); // 处理错误情况
        // this.hideLoading(); // 隐藏加载弹窗
      });
      // this.formVisible2=true;

    },
    reset(){
      this.username = "";
      this.projectName ="";
      // this.form2={};
      this. appid=''
      this.form={};
      this.load(1)
    },

  }
}
</script>

<style scoped>
.el-table .el-table__row {
  height: 50px!important;/* 设置你想要的行高 */
  line-height: 50px!important; /* 垂直居中 */
}
.yh{
  margin-left: 5px !important;
}
.form-row {
  display: flex;
  justify-content: space-between; /* 让表单项均匀分布 */
  margin-bottom: 10px; /* 行间距 */
}
.form-item {
  flex: 1; /* 让表单项均分剩余空间 */
  margin-right: 10px; /* 右边距 */
}
.form-item:last-child {
  margin-right: 0; /* 最后一个项不需要右边距 */
}


</style>
