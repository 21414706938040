import axios from 'axios';
import router from "@/router";  // 导入 Vue Router 实例

const request = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    timeout: 30000
});

// 请求拦截器
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';

    let user = JSON.parse(localStorage.getItem('honey-user') || '{}');
    config.headers['token'] = user.token;  // 设置请求头

    return config;
}, error => {
    console.error('request error:' + error);
    return Promise.reject(error);
});

// 响应拦截器
request.interceptors.response.use(
    response => {
        let res = response.data;

        // 如果是返回的文件类型
        if (response.config.responseType === 'blob') {
            return res;
        }

        // 兼容字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res;
        }

        // 处理未授权 (401) 的情况
        if (res.code === '401') {
            localStorage.removeItem('honey-user'); // 清除 token
            if (router.currentRoute.path !== '/login') {
                // 确保不重复导航
                return router.push('/login').catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        console.error('Navigation error:', err);
                    }
                });
            }
        }

        return res;
    },
    error => {
        console.error('err' + error);  // 调试输出
        return Promise.reject(error);
    }
);

export default request;
