<template>

  <div class="container">

    <div class="button" @click="help1">1.小程序用法参考 </div>
    <div class="button" @click="help2">2.常见问题与解答 </div>
  </div>

</template>

<script>
export default {
  name: "AdRegulation",
  data() {
    return {

    };
  },
  methods:{
    help1() {
      if (this.$route.path !== '/help') {
        this.$router.push('/help?id=173212919623626564');
      }
    },
    help2() {
      if (this.$route.path !== '/help') {
        this.$router.push('/help?id=173212914766350820');
      }
    },
  }
};
</script>

<style scoped>
.container {
  display: flex; /* 使用 flex 布局 */
  //justify-content: center; /* 水平居中 *//**/
  align-items: center; /* 垂直居中 */
  gap: 20px; /* 按钮之间的间距 */
  margin: 50px;
  padding-left: 70px;
}

.button {
  flex: 1; /* 按钮自适应宽度 */
  max-width: 300px; /* 限制按钮的最大宽度 */
  height: 200px; /* 固定高度 */
  display: flex; /* 使内部文字可以用 flex 布局 */
  justify-content: center; /* 水平居中文字 */
  align-items: center; /* 垂直居中文字 */
  font-size: 25px; /* 字体大小 */
  font-weight: bold; /* 加粗字体 */
  color: white; /* 文字颜色 */
  background: linear-gradient(45deg, #a8d4ff, #0aa8ff); /* 悬浮时背景变换 */
  border: none; /* 无边框 */
  border-radius: 25px; /* 微圆角 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 阴影效果 */
  cursor: pointer; /* 鼠标样式 */
  transition: all 0.3s ease; /* 动画效果 */
}

.button:hover {
  transform: scale(1.05); /* 鼠标悬浮时放大 */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3); /* 加强阴影 */
  background: linear-gradient(45deg, #9013fe, #fb7542); /* 悬浮时背景变换 */
}

.button:active {
  transform: scale(0.98); /* 点击时缩小 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 点击时阴影减弱 */
}
</style>
