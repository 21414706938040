<template>
  <div class="forbidden-container">
    <div class="icon">🚫</div>
    <h1>403</h1>
    <h2>Access Denied</h2>
    <p>You don't have permission to access this page.</p>
    <router-link to="/login" class="btn">返回登录页面</router-link>
  </div>
</template>

<script>
export default {
  name: "ForbiddenPage",
};
</script>

<style scoped>
.forbidden-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f4f6;
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 120px;
  color: #ff6b6b;
  margin-bottom: 10px;
}

h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

p {
  color: #777;
  margin-bottom: 30px;
}

.icon {
  font-size: 60px;
  color: #ff6b6b;
  margin-bottom: 10px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4a90e2;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #357ab8;
}
</style>
