import Vue from 'vue'
import VueRouter from 'vue-router'

import Manager from "@/views/Manager.vue";
import Home from "@/views/userHome.vue";
import users from "@/views/manager/users.vue";
import Auth from "@/views/Auth.vue";
import project from "@/views/project/projects.vue";
import notice from "@/views/manager/notice.vue";
import log from "@/views/manager/log.vue";
import data from "@/views/project/udata.vue";
import account from "@/views/account.vue";

import specification from "@/views/specification.vue";
import udata from "@/views/project/udata.vue";
import userHome from "@/views/userHome.vue";
import contents from "@/views/project/contents.vue";
import help from "@/views/help.vue";



Vue.use(VueRouter)

const routes = [
  {
    path: '/user',
    name: 'Manager',
    component: Manager,
    redirect: '/userHome',
    meta: { title: '首页' },
    children: [

      {
        path: '/userHome',
        name: '主页',
        component: userHome,
        meta: { title: '主页' },
      },
      {
        path: '/manager/users',
        name: 'users',
        component: users,
        meta: { title: '用户管理' },
      },
      {
        path: '/project/projects',
        name: 'projects',
        component: project,
        meta: { title: '项目管理' },
      },
      {
        path: '/project/contents',
        name: 'contents',
        component: contents,
        meta: { title: '内容管理' },
      },
      {
        path: '/manager/notice',
        name: 'notice',
        component: notice,
        meta: { title: '公告管理' },
      },

      {
        path: '/manager/log',
        name: 'log',
        component: log,
        meta: { title: '日志' },
      },
      {
        path: '/project/udata',
        name: 'udata',
        component: udata,
        meta: { title: '收益数据' },
      },
      {
        path: '/account',
        name: 'account',
        component: account,
        meta: { title: '账户' },
      },
      {
        path: '/specification',
        name: 'specification',
        component: specification,
        meta: { title: '帮助文档' },
      },
      {
        path: '/help',
        name: 'help',
        component: help,
        meta: { title: 'help' },
      },

    ]
  },
  {
    path: '/ele',
    name: 'ele',
    component: () => import('../views/element.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },
  {
    path:'/403',
    name: 'auth',
    component: () => import('../views/Auth.vue'),
    meta: {title: '403'}
  },
  {
    path:'*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {title: '404'}
  },
  {
    path:'/PendingApproval',
    name: 'PendingApproval',
    component: () => import('../views/PendingApproval.vue'),
    meta: {title: 'PendingApproval'}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let adminPaths = ['/manager/users', '/manager/log', '/manager/notice'];
  let user = JSON.parse(localStorage.getItem('honey-user') || '{}');

  if (user.role !== 1 && adminPaths.includes(to.path)) {
    next('/403'); // 权限不足，重定向到403页面
  } else {
    next(); // 正常继续
  }
});






export default router












