<template>
  <div>

    <el-container>
<!--#d2dde1-->
      <el-aside v-bind:class="{'collapsed': isCollapse}" style=" min-height:100vh ;background-color: #f5f6f8" :width="asideWidth" >
        <div style="height: 60px; line-height: 60px ;  display: flex; color: #070b0e ;align-items: center; cursor: pointer; justify-content: center; " @click="goToHome">
          <img src="/coralicon.png" alt="logo" style="width: 25px; height: 25px; margin-right: 5px;cursor: pointer;" @click="goToHome">
          <span v-if="!isCollapse" style="font-weight: bold ;cursor: pointer;" @click="goToHome" >珊瑚聚合广告</span>
        </div>

        <el-menu :collapse="isCollapse" router style="border:none; " :default-active="$route.path"
                 background-color="#f5f6f8" text-color="#070b0e"  >
          <el-menu-item  index="/userHome">
            <div @click="goToHome" style="display: flex; align-items: center; justify-content: center;">
              <i class="el-icon-house" @click="goToHome"></i>
              <span slot="title" style="cursor: pointer;" @click="goToHome" >首页</span>
            </div>
          </el-menu-item>

          <el-submenu  index="5" v-if="user.role===1">
            <template v-slot:title style="display: flex; align-items: center; justify-content: center;" >
              <!--            <i class="el-icon-menu"></i>-->
              <i class="el-icon-monitor"></i>
              <span v-if="!isCollapse">系统管理</span>
            </template>

            <el-menu-item router index="/manager/users" class="el-icon-user" >用户管理</el-menu-item>
<!--            <el-menu-item router index="/manager/project" class="el-icon-price-tag" >项目管理</el-menu-item>-->

            <el-menu-item router index="/manager/notice" class="el-icon-bell" >公告管理</el-menu-item>
            <el-menu-item router index="/manager/log" class="el-icon-location-outline" >操作日志</el-menu-item>
          </el-submenu>

          <el-submenu  index="6" >

            <template v-slot:title style="display: flex; align-items: center; justify-content: center;">
              <i class="el-icon-menu"></i>
              <span v-if="!isCollapse">应用管理</span>
            </template>
            <el-menu-item router index="/project/udata" class="el-icon-s-data" >收益数据</el-menu-item>
            <el-menu-item router index="/project/projects" class="el-icon-price-tag">应用信息</el-menu-item>
            <el-menu-item router index="/project/contents" class="el-icon-collection" v-if="isS">内容管理</el-menu-item>
          </el-submenu>

          <el-menu-item index="/account" route="">
            <div style="display: flex; align-items: center; justify-content: center;">
              <i class="el-icon-user"></i>
              <span slot="title" style="cursor: pointer;" @click="">账户信息</span>
            </div>
          </el-menu-item>
<!--          index="/specification"-->
          <el-menu-item  @click="help" style="display: flex; align-items: center; ">
            <div >
              <i class="el-icon-tickets"></i>
              <span slot="title" :class="{ 'is-active': isActive }" style="cursor: pointer;" >帮助文档</span>
            </div>
          </el-menu-item>

<!--          <el-menu-item index="/" route="">-->
<!--            <div>-->
<!--              <i class="el-icon-collection-tag"></i>-->
<!--              <span slot="title" style="cursor: pointer;" @click="">关于我们</span>-->
<!--            </div>-->
<!--          </el-menu-item>-->
        </el-menu>

      </el-aside>



      <el-container>

        <el-header  style="padding-top: 0 !important; /* 确保没有额外的 padding-top */">

          <i :class="iconClass" @click="handleCollapse" style="cursor: pointer; margin-right: 10px;"></i>

          <div class="el-icon-bell" style="padding: 10px 20px;border-radius: 5px; color: #3a8ee6"  > {{title1}}</div>
          <div style="flex: 1;width: 0; display: flex; justify-content: flex-end; align-items: center;">
            <el-dropdown>
              <div style="display: flex; justify-content: center; align-items: center; cursor: pointer; padding-right: 10px" >


                <img src="/coral.png" alt="" style="width: 30px; height: 30px; margin-right: 5px;">
                <span v-if="user.name">{{ user.name }}</span>
              </div>
              <el-dropdown-menu slot="dropdown"  >
                                <el-dropdown-item @click.native="goToAccount">账户信息</el-dropdown-item>
                                <el-dropdown-item @click.native="openPasswordDialog">修改密码</el-dropdown-item>

                <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div>

            </div>
          </div>
          <div  >
            <!-- Form -->
            <el-dialog     title="修改密码" :visible.sync="dialogFormVisible"  width="25%" :close-on-click-modal="false">
              <el-form :model="form"  :center="true">
                <el-form-item label="原密码" :label-width="formLabelWidth">
                  <el-input type="password" v-model="form.oldPassword" autocomplete="off" ></el-input>
                </el-form-item>
                <el-form-item label="新密码" :label-width="formLabelWidth">
                  <el-input type="password" v-model="form.newPassword" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" :label-width="formLabelWidth">
                  <el-input type="password" v-model="form.confirmPassword" autocomplete="off"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="handlePasswordChange">确认修改</el-button>
              </div>
            </el-dialog>
          </div>

        </el-header>

        <el-main>
          <router-view @update:user="updateUser"
          @update:title1="handleTitleUpdate"></router-view>

        </el-main>

      </el-container>

    </el-container>


  </div>

</template>

<script>
// @ is an alias to /src

import router from "@/router";
import { Loading } from 'element-ui';

export default {
  name: 'HomeView',

  data(){
    return {
      isS:null,
      isActive: false,
      isCollapse:false,
      asideWidth:"240px",
      DayIncome1:0,
      user: JSON.parse(localStorage.getItem('honey-user')||'{}'),
      formUan1:[],
      content0:"",
      title1:"",
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        oldPassword:'',
        newPassword:'',
        confirmPassword:'',
      },
      formLabelWidth: '80px',
      old:'',
    }
  },
  computed: {

    iconClass() {
      return this.isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'; // 根据状态返回不同的图标
    }
  },
  mounted() {


    const storedUser = JSON.parse(localStorage.getItem('honey-user'));
    if (storedUser) {
      // console.log(storedUser);
      this.user.name=storedUser.name;
      this.isS =storedUser.scon;
      // console.log(storedUser)
      // console.log('this.isS is:',this.isS)
      // this.loadUan1()
      this.checkWindowSize();
      window.addEventListener('resize', this.checkWindowSize);
    }
    if(!this.user.id){
      this.$router.replace('/login');
    }


  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWindowSize);
  },
  methods:{
    checkWindowSize() {
      this.isCollapse = window.innerWidth < 768;
      this.asideWidth = this.isCollapse ? '64px' : '240px';
    },
    showLoading() {
      this.loadingInstance = Loading.service({
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
    },

    // 隐藏加载弹窗
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },

    help() {
      if (this.$route.path !== '/specification') {
        this.$router.push('/specification');
      }
    },

    handleTitleUpdate(newTitle) {
      this.title1 = newTitle; // 更新父组件中的 title1
    },
    goToAccount() {
      if (this.$route.path !== '/account') {
        this.$router.push('/account');
      }
    },
    handlePasswordChange() {
      if (this.form.newPassword !== this.form.confirmPassword) {
        this.$message.error('两次密码不一致');
        return;
      }
      if (!this.form.oldPassword || !this.form.newPassword || !this.form.confirmPassword) {
        this.$message.error("未填写完成");
        return;
      }

      this.old = JSON.parse(localStorage.getItem('honey-user')).password;
      if(this.form.oldPassword !== this.old){
        this.$message.error('原密码填写错误');
        return;
      }
      // 在此处执行密码修改逻辑，例如发送请求到服务器
      this.user.password = this.form.confirmPassword;
      // console.log(this.user.password);
      this.showLoading(); // 显示加载弹窗
      this.$request.put('/user/update',this.user).then((res) => {
        if (res.code === '200') {
          this.$message.success('密码修改成功');
          this.hideLoading(); // 隐藏加载弹窗
          // localStorage.setItem('honey-user', JSON.stringify(this.user));
          this.dialogFormVisible = false; // 关闭模态框
          // this.$emit('update:user',this.user);
          localStorage.removeItem("honey-user")
          this.$router.push('/login');
        }else {
          this.$message.error(res.mag)
          this.hideLoading(); // 隐藏加载弹窗
        }
      })
      ;


    },
    openPasswordDialog() {
      this.dialogFormVisible = true; // 打开模态框
    },
    updateUser(user){
      this.user = JSON.parse(JSON.stringify(user));
    },
    logout(){
      localStorage.removeItem("honey-user")
      this.$router.push('/login')

    },
    goToHome() {
     // 重新加载当前页面
      if (this.$route.path !== '/userHome') {
        this.$router.replace('/userHome');
        // location.reload();
      }

    },
    handleCollapse(){
      this.isCollapse=!this.isCollapse;
      this.asideWidth = this.isCollapse ? '64px' : '240px';
    }
  }

}
</script>

<style>
.el-menu-item {
  height: 40px !important;
  line-height: 40px !important;
  color: rgb(12, 12, 12) !important;
  display: flex;
  align-items: center; /* 确保图标和文字垂直居中 */
}

.el-menu-item i {
  margin-right: 8px; /* 图标和文字间距 */
  color: #070b0e; /* 默认图标颜色 */
  transition: color 0.3s ease; /* 动画过渡效果 */

  align-items: center; /* 图标在竖直方向居中 */
}

.el-menu-item:hover {
  color: rgb(64, 158, 255) !important;
}

.el-menu-item:hover i {
  color: rgb(64, 158, 255) !important;
}

.el-menu-item.is-active {
  color: rgb(64, 158, 255) !important;
}

.el-menu-item.is-active i {
  color: rgb(64, 158, 255) !important;
}

.el-menu-item:hover {
  //color: #ffffff !important;
  color: rgb(64, 158, 255) !important;
}
.el-menu-item.is-active {
  //background-color: rgba(66, 119, 232, 0.84) !important;
  width: 100%;
  //border-radius: 15px !important;
  //margin: 3px !important;
}
.el-menu-item{
  height: 40px !important;
  line-height: 40px !important;
  //border-radius: 15px !important;
}

.collapsed {
  transition: width 0.3s;
}
.custom-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.el-aside {
  transition: all 0.3s ease;
  background-color: #f4f6f8;
  //border-right: 1px solid #e0e0e0;
}
.el-submenu .el-menu-item{
  padding-left: 55px !important;
  width: 100%;

}

.el-menu-item:hover {
  //background-color: #3a8ee6 !important; /* 蓝色背景
  color: white !important; /* 白色文字 */

  //border-radius: 15px !important;
  width: 100%;

}
.el-submenu__title:hover {

  //color: white !important; /* 白色文字 */
  /*border-radius: 15px; !* 可选：圆角效果 *!!**!*/
  width: 100%;

}

.el-menu-item.is-active {

  //border-radius: 15px !important;
  //margin: 3px !important;
}
.el-header {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.07);
  padding-top: 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
}



</style>
