<template>
  <div>
<!--    <span class="yh">收益数据</span>-->
    <div slot="header" class="clearfix">
      <div style="padding-bottom: 20px" v-if="isA" key="2">
        <el-button type="primary" plain @click="openAddDialog" style="width: 120px;" >新增</el-button>
        <el-button type="danger" plain @click="delbatch" :loading="isloading1">批量删除</el-button>
        <el-button type="info" plain @click="reset">刷新</el-button>

      </div>
      <div style="margin-bottom: 10px; display: flex; align-items: center; justify-content: space-between;">
        <!-- 左侧：输入框和按钮 -->
        <div style="display: flex; align-items: center; ">
          <el-autocomplete v-if="isA" key="1"
              style="width: 200px;;padding-right: 10px"
              v-model="user2"
              placeholder="查询用户"
              :fetch-suggestions="querySearch"

          ></el-autocomplete>
<!--          <el-input style="width: 200px;padding-right: 10px" placeholder="查询用户" v-model="user2" v-if="isA" key="1"></el-input>-->
          <el-input style="width: 200px;padding-right: 10px" placeholder="项目名称" v-model="projectName1" key="41" v-if="isA"></el-input>
          <el-input style="width: 200px; padding-right: 10px" placeholder="广告位ID" v-model="appid"></el-input>
          <el-button type="primary"  @click="check1(1)">查询</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </div>

        <!-- 右侧：合计 -->
        <div style="font-weight: bold; text-align: right; padding-right: 20px;">
          <span style="color: slategray">合计：</span><span style="color: #0685cc;">{{ totalIncome.toFixed(2) }}</span>
        </div>
      </div>


      <el-table :data="udata" stripe :header-cell-style="{backgroundColor:'#dae9f8' ,font:'bold'}"
                style="width: 100%; padding-top: 10px"
                @selection-change="handleSelectionChange">


        <el-table-column  type="selection" width="50" align="center"  v-if="ist" key="3" ></el-table-column >
        <el-table-column
            label="日期"
            :min-width="110"
            :show-overflow-tooltip="true"
            align="center"
            key="udate"
        >
          <template v-slot="scope">
    <span>
      {{
        (() => {
          const date = new Date(scope.row.udate);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        })()
      }}
    </span>
          </template>
        </el-table-column>

        <el-table-column label="用户" :min-width="100" v-if="ist" :show-overflow-tooltip="true" align="center" key="34" >
          <template v-slot="scope">
            <span>{{ scope.row.username}}</span> <!-- 使用 scope.row 直接引用 -->
          </template>
        </el-table-column>
        <el-table-column label="项目名称" :min-width="120"  :show-overflow-tooltip="true" align="center" key="5">
          <template v-slot="scope">
            <span>{{ scope.row.projectName}}</span> <!-- 使用 scope.row 直接引用 -->
          </template>
        </el-table-column>
        <el-table-column label="广告位ID" :min-width="110"  :show-overflow-tooltip="true" align="center" key="6">
          <template v-slot="scope">
            <span>{{ scope.row.appid}}</span> <!-- 使用 scope.row 直接引用 -->
          </template>
        </el-table-column>
        <el-table-column label="广告位名称" :min-width="120"  :show-overflow-tooltip="true" align="center" key="7">
          <template v-slot="scope">
            <span>{{ scope.row.appidName}}</span> <!-- 使用 scope.row 直接引用 -->
          </template>
        </el-table-column>
        <el-table-column label="展示量" :min-width="110"  :show-overflow-tooltip="true" align="center" key="8">
          <template v-slot="scope">
            <span>{{ scope.row.uread}}</span> <!-- 使用 scope.row 直接引用 -->
          </template>
        </el-table-column>
        <el-table-column label="点击量" :min-width="110"  :show-overflow-tooltip="true" align="center" key="9">
          <template v-slot="scope">
            <span>{{ scope.row.uclick}}</span> <!-- 使用 scope.row 直接引用 -->
          </template>
        </el-table-column>
        <el-table-column label="点击率" :min-width="100" align="center" key="10">
          <template slot-scope="scope">
            <span>{{ scope.row.uread > 0 ? ((scope.row.uclick / scope.row.uread) * 100).toFixed(2) : 0 }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="千次展示收益" :min-width="110" align="center" key="11">
          <template slot-scope="scope">
            <span>{{ scope.row.uread > 0 ? ((scope.row.uincome / scope.row.uread) * 1000).toFixed(2) : 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="uincome" label="收益（元）" :min-width="120" align="center" key="12"></el-table-column >
        <el-table-column label="操作" min-width="170" align="center" v-if="isA" key="16">
          <template v-slot="scope">
            <el-button type="primary" size="mini" plain @click="edit(scope.row)" key="17">编辑</el-button>
            <el-button type="danger" size="mini" plain @click="handleDelete(scope.row.id)" key="18"  >删除</el-button>
          </template>
        </el-table-column>
        <el-table-column label="创建日期" :min-width="145" :show-overflow-tooltip="true" align="center" v-if="isA" key="14">
          <template v-slot="scope">
            <div style="white-space: nowrap;">
      <span>
        {{
          (() => {
            const date = new Date(scope.row.createTime);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          })()
        }}
      </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="更新日期" :min-width="145" :show-overflow-tooltip="true" align="center" key="25" v-if="isA">
          <template v-slot="scope">
            <div style="white-space: nowrap;">
      <span>
        {{
          (() => {
            const date = new Date(scope.row.updateTime);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          })()
        }}
      </span>
            </div>
          </template>
        </el-table-column>



      </el-table>
      <div style="display: flex;margin-top: 20px;justify-content: center;;align-items: center" key="15"  >
        <el-pagination

            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="pageSize"
            layout="total,  prev, pager, next"
            :total=total>
        </el-pagination>
      </div>

    </div>
    <div >
      <el-dialog title="新增数据" :visible.sync="formVisible"  width="35%" :close-on-click-modal="false"  :before-close="qx">
        <el-form :model="form" style="width: 95%;" :rules="rules"  ref="form">

          <div class="form-row">
            <el-form-item label="用户" :label-width="formLabelWidth" >
              <el-autocomplete  class="inline-input"
                                v-model="form.username"
                                :fetch-suggestions="querySearch"
                                placeholder=""
              >
              </el-autocomplete>
            </el-form-item>
            <el-form-item prop="projectName" label="项目名称" :label-width="formLabelWidth " >
              <el-input v-model="form.projectName" autocomplete="off"></el-input>
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item prop="appid" label="广告位ID" :label-width="formLabelWidth" >
              <el-input v-model="form.appid" autocomplete="off" @blur="fetchProjectAndUser"></el-input>
            </el-form-item>
            <el-form-item prop="appidName" label="广告位名称" :label-width="formLabelWidth">
              <el-input v-model="form.appidName" autocomplete="off"></el-input>
            </el-form-item>
          </div>

          <div class="form-row">
            <el-form-item prop="uread" label="展示量" :label-width="formLabelWidth">
              <el-input v-model="form.uread" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="uclick" label="点击量" :label-width="formLabelWidth">
              <el-input v-model="form.uclick" autocomplete="off"></el-input>
            </el-form-item>
          </div>
            <div class="form-row">
          <el-form-item prop="uincome" label="收益" :label-width="formLabelWidth">
            <el-input v-model="form.uincome" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="date" label="日期" :label-width="formLabelWidth">
            <el-date-picker
                v-model="form.udate"
                type="date"
                format="yyyy-MM-dd"
                placeholder="选择日期"
                :picker-options="{ disabledDate(time) { return time.getTime() > Date.now(); } }">
            </el-date-picker>
          </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center; display: flex;justify-content: center;">
          <el-button @click="qx" style="width: 80px">取 消</el-button>
          <el-button type="primary" @click="handleAdd" style="width: 120px" :loading="isloading">提 交</el-button>
        </div>
      </el-dialog>
    </div>
    <div >
      <el-dialog title="编辑" :visible.sync="formVisible2" width="40%" :close-on-click-modal="false">
        <el-form :model="form2" style="width: 95%;">
          <div class="form-row">
            <el-form-item prop="username" label="用户" class="form-item">
              <el-input v-model="form2.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="projectName" label="项目名称" class="form-item">
              <el-input v-model="form2.projectName" autocomplete="off"></el-input>
            </el-form-item>
          </div>

          <div class="form-row">
            <el-form-item prop="appid" label="广告位ID"  class="form-item">
              <el-input v-model="form2.appid" autocomplete="off" @blur="fetchProjectAndUser"></el-input>
            </el-form-item>
            <el-form-item prop="appid" label="广告位名称"  class="form-item" >
              <el-input v-model="form2.appidName" autocomplete="off" ></el-input>
            </el-form-item>
          </div>

          <div class="form-row">
            <el-form-item prop="uread" label="展示量"   class="form-item">
              <el-input v-model="form2.uread" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="uclick" label="点击量"  class="form-item">
              <el-input v-model="form2.uclick" autocomplete="off"></el-input>
            </el-form-item>
          </div>

          <div class="form-row">
            <el-form-item prop="uincome" label="收益"  style=" justify-content: space-between; ">
              <el-input v-model="form2.uincome" autocomplete="off" style="width: 333px" ></el-input>
            </el-form-item>
            <el-form-item prop="date" label="日期"  style="justify-content: space-between; " >
              <el-date-picker
                  v-model="form2.udate"
                  type="date"
                  format="yyyy-MM-dd"
                  placeholder="选择日期"
                  :picker-options="{ disabledDate(time) { return time.getTime() > Date.now(); } }">
              </el-date-picker>
            </el-form-item>
          </div>

        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center; display: flex;justify-content: center;">
          <el-button @click="formVisible2 = false">取 消</el-button>
          <el-button type="primary" @click.stop="handleEdit" :loading="isloading2" style="width: 120px">提 交</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import axios from "axios";
import { Loading } from 'element-ui';
export default {
  name: 'udata',
  data() {
    return {
      rules: {
        appid: [
          { required: true, message: '广告位ID不能为空', trigger: 'blur' }
        ],
        // 其他表单项的验证规则
      },
      projectName1:"",
      totalIncome:0,
      showColumns: {
        selection: false,
        user: false,
        project: true, // 默认显示
        // 其他列...
      },
      isloading:false,
      isloading1:false,
      isloading2:false,

      ist:false,
      user1:JSON.parse(localStorage.getItem('honey-user')||'{}'),
      isA:null,
      udata: [],
      project:{},
      pageNum: 1,
      pageSize: 16,
      username:"",
      appid:'',
      projectName:"",
      total:0,
      formVisible: false,
      formVisible2: false,
      form: {},
      form2: {},
      formLabelWidth: '90px',
      originalUser: {},  // 存储初始数据，用于取消时恢复
      ids:[],
      formUsers:[],
      restaurants: [],
      lu:[],
      currentUser:{},
      user2:"",
      currentUserName:'',
      role1:2,
    }
  },
  computed: {
    isSelectionVisible() {
      return this.showColumns.selection && this.ist;
    },
    isUserVisible() {
      return this.showColumns.user && this.ist;
    },
    // 其他计算属性...
  },
  mounted() {

    this.loadUser()
    this.currentUser = JSON.parse(localStorage.getItem('honey-user'));
    this.currentUserName =this.currentUser.username;
    this.role1 =this.currentUser.role;
    if (this.role1 === 1) {
      this.load()
      this.isA = true

    }else {
      this.load2()

    }
    this.load3()

  },
  methods: {
    qx(){
      this.formVisible = false
      this.user2 = "";
      this.projectName ="";
      this.projectName1 ="";
      // this.form2={};
      this. appid=''
      this.form={};
    },
    showLoading() {
      this.loadingInstance = Loading.service({
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
    },

    // 隐藏加载弹窗
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },
    load4(pageNum){
      this.showLoading();
      this.appidLoad2()
      if (pageNum) {
        this.pageNum = pageNum;
      }

      this.$request.get('/udata/selectByPage', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          username: this.currentUserName,
          appid: this.appid,
          projectName:this.projectName,


        }
      }).then(res4 => {
        if (res4) {
          // console.log('this res4 is ')
          // console.log(res4)
          this.udata = res4.data.records;
          this.total = res4.data.total;
          this.hideLoading(); // 隐

        } else {
          this.udata = [];
          this.total = 0; // 或者其他处理逻辑
          this.hideLoading(); // 隐
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
        this.hideLoading(); // 隐
      });
    },
    projectLoad(pageNum){
      this.showLoading(); // 显示加载弹窗
      this.$request.get('/udata/selectByProjectName1', {
        params: {
          projectName: this.projectName1,
        }
      }).then(res => {
        if (res.data && res) {
          this.totalIncome = res.data.totalIncome;
          // console.log('this projectName Income res is:')
          // console.log(res)
          if(this.role1 === 2){
            // console.log('this totalIncome is:')
            // console.log(this.totalIncome)
          }


        } else {
          console.log(res)
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
      });
      this.hideLoading(); // 隐藏加载弹窗
      if (pageNum) {
        this.pageNum = pageNum;
      }

      this.$request.get('/udata/selectByPage2', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          projectName: this.projectName1,


        }
      }).then(res4 => {
        if (res4.data && res4.data.records) {
          this.udata = res4.data.records;
          this.total = res4.data.total;
          // console.log('this.udata is:')
          // console.log(this.udata)
          this.hideLoading(); // 隐藏加载弹窗
        } else {
          this.udata = [];
          this.total = 0; // 或者其他处理逻辑
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    appidLoad(){
      this.showLoading(); // 显示加载弹窗
      this.$request.get('/udata/selectByAppid', {
        params: {
          appid: this.appid,

        }
      }).then(res => {
        if (res.data && res) {
          this.totalIncome = res.data.totalIncome;
          // console.log('this res is:')
          // console.log(res)
          if(this.role1 === 2){
            // console.log('this totalIncome is:')
            // console.log(this.totalIncome)
            this.hideLoading(); // 隐藏加载弹窗
          }


        } else {
          console.log(res)
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    appidLoad2(){
      this.showLoading(); // 显示加载弹窗
      this.$request.get('/udata/selectByAppid2', {
        params: {
          appid: this.appid,
          username: this.currentUserName,
        }
      }).then(res => {
        if (res.data && res) {
          this.totalIncome = res.data.totalIncome;
          // console.log('this res is:')
          // console.log(res)
          this.hideLoading(); // 隐藏加载弹窗
          if(this.role1 === 2){
            // console.log('this totalIncome is:')
            // console.log(this.totalIncome)
            this.hideLoading(); // 隐藏加载弹窗
          }

          this.hideLoading(); // 隐藏加载弹窗
        } else {
          console.log(res)
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    load10() {
      this.showLoading(); // 显示加载弹窗
      this.$request.get('/udata/selectByUsername', {
        params: {
          username: this.user2,
        }
      }).then(res => {
        if (res.data && res) {
          this.totalIncome = res.data.totalIncome;
          this.hideLoading(); // 隐藏加载弹窗
        } else {
          console.log(res)
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    load3() {
      this.showLoading(); // 显示加载弹窗
      this.$request.get('/udata/selectByUsername', {
        params: {
          username: this.currentUserName,
        }
      }).then(res => {
        if (res.data && res) {
          this.totalIncome = res.data.totalIncome;
          // console.log('this res is:')
          // console.log(res)
          // console.log('this totalIncome is:')
          // console.log(this.totalIncome)
          this.hideLoading(); // 隐藏加载弹窗
        } else {
          console.log(res)
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    fetchProjectAndUser() {

      this.$request.get('/projects/selectByPage', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          username: this.username,
          appid: this.form.appid,
          projectName:this.projectName,
        }
      }).then(res5 => {
        if (res5.data && res5.data.records) {
          // console.log("this res5.data is :")
          // console.log(res5.data.records[0])
          this.form.projectName = res5.data.records[0].projectName;
          this.form.username = res5.data.records[0].username;
          this.form.appidName = res5.data.records[0].appidName;
          this.$forceUpdate()
          //
          // console.log("this.form is:")
          // console.log(this.form)
          this.hideLoading(); // 隐藏加载弹窗

        } else {
          // console.error("获取项目名称和用户失败：", error);
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err2 => {
        // console.error(err2); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
      this.hideLoading(); // 隐藏加载弹窗
    },

    check1(){

      if (this.role1 === 1) {
        this.load()

      }else {
        this.load4()

      }
    },

    delbatch(){
      if(!this.ids.length){
        this.$message.warning('请选择要删除的数据')

        return
      }
      this.$confirm('是否要批量删除?','确认删除',{type:"warning"}).then(response => {
        this.isloading1=true
        this.$request.delete('/udata/delete/batch',{data:this.ids}).then((res1) => {

          // this.showLoading(); // 显示加载弹窗
          if (res1.code === '200') {
            this.isloading1=false
            this.$message.success('删除成功');
            // localStorage.setItem('honey-user', JSON.stringify(this.user));
            // this.formVisible2 = false; // 关闭模态框
            this.reset()
            // this.hideLoading(); // 隐藏加载弹窗
          }else {
            this.$message.error(res1.mag)
            this.isloading1=false
            // this.hideLoading(); // 隐藏加载弹窗
          }
        })
      }).catch((error) => {this.isloading1=false;}

      )
    },
    handleDelete(id){
      // console.log('Deleting project with ID:', id);
      this.$confirm('是否要删除?','确认删除',{type:"warning"}).then(response => {
        this.showLoading();
        this.$request.delete('/udata/delete/'+id).then((res2) => {
          if (res2.code === '200') {

            this.$message.success('删除成功');
            // localStorage.setItem('honey-user', JSON.stringify(this.user));
            // this.formVisible2 = false; // 关闭模态框
            this.reset()
          }else {
            this.hideLoading();
            this.$message.error(res2.mag)
          }
        })
      }).catch((error) => {
        console.log(error);this.hideLoading();})

    },
    querySearch(queryString, cb) {
      // 假设你有一个用户数组，你可以根据输入的内容过滤用户

      const results = queryString ? this.formUsers.filter(this.createFilter(queryString)) : this.formUsers;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleAdd(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 表单验证成功时执行的操作
          // console.log("表单验证成功，提交数据");
          this.isloading=true
          this.form.udate = new Date(this.form.udate).getTime(); // 转换为时间戳
          this.project = this.form;
          // console.log("this form is:")
          // console.log(this.project);
          this.$request.post('/udata/add', this.project).then((res3) => {
            // this.showLoading(); // 显示加载弹窗
            if (res3.code === '200') {
              this.isloading=false
              // this.hideLoading(); // 隐藏加载弹窗

              this.$message.success('添加成功');
              // localStorage.setItem('honey-user', JSON.stringify(this.user));
              this.formVisible = false; // 关闭模态框
              // this.load()
              this.reset()
            }else {
              this.isloading=false
              // this.hideLoading(); // 隐藏加载弹窗
              this.$message.error(res3.msg)
              this.reset()
            }
          }).catch(error => {
            this.reset()
            this.isloading=false
            console.error(error); // 处理错误情况
            // this.hideLoading(); // 隐藏加载弹窗
          });
          // 执行提交逻辑，例如发送请求
        } else {
          // this.$message.error("广告位ID是必填项")
          return false; // 阻止提交
        }
      });

    },
    edit(row){
      this.form2.udate = new Date(this.form2.udate).getTime(); // 转换为时间戳
      this.form2 = JSON.parse(JSON.stringify(row));
      this.formVisible2=true; // 打开模态框

      // console.log('this.form2 is :')
      // console.log(this.form2);

    },
    loadUser(){

      this.$request.get('/user/selectAll').then((result) => {
        if (result.data) {
          this.lu = result.data
          this.formUsers = this.lu.map(lu=> ({ value: lu.username}));
          this.hideLoading(); // 隐藏加载弹窗
        } else {

          localStorage.removeItem("honey-user")
          this.$router.push('/login');
        }


      })
    },
    load2(pageNum) {
      if (pageNum) {
        this.pageNum = pageNum;
      }
      this.showLoading(); // 显示加载弹窗
      this.$request.get('/udata/selectByPage', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          username: this.currentUserName,
          appid: this.appid,
          projectName:this.projectName,

        }
      }).then(res4 => {
        if (res4.data && res4.data.records) {
          this.udata = res4.data.records;
          this.total = res4.data.total;
          // console.log('this.udata is:')
          // console.log(this.udata)
          this.hideLoading(); // 隐藏加载弹窗
        } else {
          this.udata = [];
          this.total = 0; // 或者其他处理逻辑
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    load(pageNum) {
      this.showLoading();
      if(this.projectName1){
        this.projectLoad()
      }
      if (this.user2){
        this.load10()
      }
      this.appidLoad()
      if (pageNum) {
        this.pageNum = pageNum;
      }

      this.$request.get('/udata/selectByPage', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          username: this.user2,
          appid:this.appid,
          projectName:this.projectName1,

        }
      }).then(res5 => {
        if (res5.data && res5.data.records) {
          this.udata = res5.data.records;
          this.total = res5.data.total;
          this.ist = true
          // console.log('this.udata is:')
          // console.log(this.udata)
          // this.totalIncome = res5.data.records.reduce((sum, item) => {
          //   return sum + parseFloat(item.uincome); // 转换为数字并累加
          // }, 0);
          this.hideLoading(); // 隐藏加载弹窗
        } else {
          this.udata = [];
          this.total = 0; // 或者其他处理逻辑
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err2 => {
        console.error(err2); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      if (this.role1 === 1) {
        this.load()
      }else {
        this.load2()
      }
    },
    handleSelectionChange(rows){
      this.ids = rows.map(item => item.id);
    },
    openAddDialog(){
      this.formVisible=true;
    },
    handleEdit(){
      this.isloading2=true
      this.form2.udate = new Date(this.form2.udate).getTime(); // 转换为时间戳
      this.project = this.form2;
      // this.showLoading(); // 显示加载弹窗
      this.$request.put('/udata/update', this.project).then((res7) => {
        if (res7.code === '200') {
          this.isloading2=false
          // this.hideLoading(); // 隐藏加载弹窗
          this.$message.success('修改成功');
          // localStorage.setItem('honey-user', JSON.stringify(this.user));
          this.formVisible2 = false; // 关闭模态框
          this.reset()
        }else {
          this.$message.error(res7.msg)
          this.isloading2=false
          // this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(error => {
        console.error(error); // 处理错误情况
        this.isloading2=false
         // this.hideLoading(); // 隐藏加载弹窗
      });


    },
    reset(){
      this.load3()
      this.user2 = "";
      this.projectName ="";
      this.projectName1 ="";
      // this.form2={};
      this. appid=''
      this.form={};
      if (this.role1 === 1) {
        this.load(1)
      }else {
        this.load2(1)
      }
    },

  }
}
</script>

<style scoped>
.clearfix{
  padding-top: 10px;
}
.el-table .el-table__row {
  height: 50px!important;/* 设置你想要的行高 */
  line-height: 50px!important; /* 垂直居中 */
}
.el-table-column{
 font-size: 44px !important;
}
.yh{
  margin-left: 5px !important;
}
.form-row {
  display: flex;
  justify-content: space-between; /* 让表单项均匀分布 */
  margin-bottom: 10px; /* 行间距 */
}
.form-item {
  flex: 1; /* 让表单项均分剩余空间 */
  margin-right: 10px; /* 右边距 */
}
.form-item:last-child {
  margin-right: 0; /* 最后一个项不需要右边距 */
}
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 20px;
  color: #007bff; /* 或其他颜色 */
}

</style>
