
<template>
  <div>
    <div style="display: flex; justify-content: space-between; flex-wrap: wrap;">
      <el-card class="box-card" style="margin: 15px; height: 200px; flex: 1 1 300px; border-radius: 8%; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); background: linear-gradient(to right, #6e69f8, #6560fa); display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div style="text-align: center;">
          <div style="font-size: 28px; color: white;">昨日收益</div>
          <div style="font-size: 34px; font-weight: bold; color: white;">{{this.DayIncome.toFixed(2)}}</div>
        </div>
      </el-card>

      <el-card class="box-card" style="margin: 15px; height: 200px; flex: 1 1 300px; border-radius: 8%; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); background: linear-gradient(to right, #00d0ff, #0aa8ff); display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div style="text-align: center;">
          <div style="font-size: 28px; color: white;">近七日收益</div>
          <div style="font-size: 34px; font-weight: bold; color: white;">{{this.sevenDayIncome.toFixed(2)}}</div>
        </div>
      </el-card>

      <el-card class="box-card" style="margin: 15px; height: 200px; flex: 1 1 300px; border-radius: 8%; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); background: linear-gradient(to right, #fb7542, #fb7542); display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div style="text-align: center;">
          <div style="font-size: 28px; color: white;">本月收益</div>
          <div style="font-size: 34px; font-weight: bold; color: white;">{{this.moIncome.toFixed(2)}}</div>
        </div>
      </el-card>

      <el-card class="box-card" style="margin: 15px; height: 200px; flex: 1 1 300px; border-radius: 8%; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); background: linear-gradient(to right, #FFFFFF, #F0F8FF); display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div style="text-align: center;">
          <div style="font-size: 28px; color: black;">总收益</div>
          <div style="font-size: 34px; font-weight: bold; color: black;">{{this.totalIncome.toFixed(2)}}</div>
        </div>
      </el-card>
    </div>

    <div style="padding-left: 30px; padding-top: 50px;">
      <canvas id="incomeChart" style="width: 92%; height: 400px;"></canvas>
    </div>

    <el-card
        style="width: 300px; position: fixed; top: 80px; right: 30px; z-index: 1000;"
        v-if="visible" key="1">
      <div slot="header" class="clearfix" style="display: flex; justify-content: space-between;">
        <span>{{title0}}</span>
        <span @click="closeCard" style="cursor: pointer; color: #064df8;">关闭</span>
      </div>
      <div style="font-size: 14px">
        {{ announcement }}
      </div>
    </el-card>
    <div>

    </div>

  </div>
</template>

<script >
import ResizeObserver from 'resize-observer-polyfill';
import { Loading } from 'element-ui';
import { Chart, registerables } from 'chart.js';
import Log from "@/views/manager/log.vue";
Chart.register(...registerables);
export default {
  name: 'Home',
  data(){
    return {
      lu:[],
      formUsers:[],
      isCollapse:false,
      asideWidth:"200px",
      user:JSON.parse(localStorage.getItem('honey-user')||'{}'),
      visible: false, // 控制卡片的显示与隐藏 可以在公告这个数据库中设置可见属性,一个是是否可见,一个是内容,加上一个索引就是三项!
      announcement: "", // 公告内容
      isAdmin: true, // 根据实际情况设置，true表示管理员
      totalIncome:0,
      moIncome:0,
      sevenDayIncome:0,
      DayIncome:0,
      records1:[],
      formUan:[],
      title0:"",
      formUan1:[],
      title1:"",
    }
  },
  computed: {

  },
  mounted() {
    this.loadUser()
    this.loadUan1()

  },

  methods:{
    async loadUser(){

      this.$request.get('/user/selectAll').then((result) => {
        this.lu = result.data
        this.formUsers = this.lu.map(lu=> ({ value: lu.username}));
        this.showLoading(); // 显示加载弹窗
        this.loadUan()
        this.load2()
        this.load3()
        this.load4()
        this.load5()
        this.hideLoading(); // 隐藏加载弹窗

      }).catch(async error => {
        localStorage.removeItem('honey-user'); // 清除 token
        console.error(error); // 处理错误情况
        // 只有在当前路由不是 '/login' 时才重定向
        if (this.$router.currentRoute.path !== '/login') {
          await this.$router.push('/login').catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              console.error('Navigation error:', err);
            }
          });
        }

      });
    },
    showLoading() {
      this.loadingInstance = Loading.service({
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
    },

    // 隐藏加载弹窗
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },
    loadUan() {
      this.$request.get('/uannounce/selectAll').then((result) => {
        this.lu = result.data;
            if (this.lu) {
              this.formUan = this.lu.map(lu => ({
                utitle: lu.utitle,
                ucontent: lu.ucontent,
                id: lu.id,
                urole: lu.urole,
                vis: lu.vis
              })
              );
            }


        const targetItem = this.formUan.find(item => item.id === 5);
        if (targetItem) {
          this.announcement = targetItem.ucontent; // 赋值
        }
        const targetItem2 = this.formUan.find(item => item.id === 5);
        if (targetItem2) {
          if(targetItem2.vis==1){
            this.visible = true
            this.title0 = targetItem2.utitle
          }
          if(targetItem2.vis==0){
            this.visible = false
          }

        }

      });
    },


    renderChart() {
      try {
        if (typeof ResizeObserver === 'undefined') {
          this.$message.warning('当前浏览器版本过旧，部分功能无法实现，请更换浏览器');
          return;
        }

        const ctx = document.getElementById('incomeChart').getContext('2d');
        const labels = this.records1.map(record => {
          const date = new Date(record.date);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        });
        const data = this.records1.map(record => record.income.toFixed(2));

        new Chart(ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets: [{
              label: "收益趋势(近7天)",
              data: data,
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 2,
              fill: false,
              lineTension: 0.25,
            }]
          },
          options: {
            plugins: {
              legend: {
                labels: {
                  font: {
                    size: 16,
                    weight: 'bold'
                  }
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      } catch (error) {
        console.error('绘制图表时出错:', error);
        this.$message.warning('绘制图表时出错,请更换浏览器尝试');
      }
    },

    getD() {
      return this.$request.get('/udata/DayselectByUsername', {
        params: {
          username: this.user.username,
        }
      }).then(res => {
        if (res.data) {
          this.DayIncome1 = res.data.DayIncome;
          // console.log("this.DayIncome1 in getD:");
          // console.log(this.DayIncome1);
        } else {
          console.log(res);
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
      });
    },
    loadUan1() {
      this.getD().then(() => {
        return this.$request.get('/uannounce/selectAll');
      }).then((result) => {
        this.lu = result.data;
        if (this.lu) {
          this.formUan1 = this.lu.map(lu => ({
                utitle: lu.utitle,
                ucontent: lu.ucontent,
                id: lu.id,
                urole: lu.urole,
                vis: lu.vis
              })
          );
        }

        // console.log("this.DayIncome1 in loadUan1:");
        // console.log(this.DayIncome1); // 这里应该是最新的值
        const targetItem1 = this.formUan1.find(item => item.id === 1);
        const targetItem2 = this.formUan1.find(item => item.id === 2);
        const targetItem3 = this.formUan1.find(item => item.id === 3);
        // const targetItem4 = this.formUan1.find(item => item.id === 4);
        if(this.DayIncome1 !==0){
          this.title1 = targetItem3.ucontent;
        }
        if(this.DayIncome1===0){
          const now = new Date();
          const hours = now.getHours();
          const targetItem3 = this.formUan1.find(item => item.id === 3);
          if(targetItem3){
            if(targetItem3.vis === 1 && hours> 19 ){

              this.title1 = targetItem3.ucontent;
            }else{
              if (hours> 19){
                this.title1 = targetItem1.ucontent;
                // console.log('now the title1 must be:')
                // console.log(this.title1)
              }else {
                this.title1 = targetItem2.ucontent;
                // console.log('now the title1 must be:')
                // console.log(this.title1)
              }
            }
          }



        }
        this.$emit('update:title1', this.title1);
      });
    },
    load5() {

      this.$request.get('/udata/DayselectByUsername', {
        params: {
          username: this.user.username,
        }
      }).then(res => {
        if (res.data && res) {
          this.DayIncome = res.data.DayIncome;
          // console.log('this res is:')
          // console.log(res)
          // console.log('this DayIncome is:')
          // console.log(this.DayIncome)

        } else {
          console.log(res)

        }
      })
    },
    load4() {

      this.$request.get('/udata/sevenDayselectByUsername', {
        params: {
          username: this.user.username,
        }
      }).then(res => {
        if (res.data && res) {
          this.sevenDayIncome = res.data.sevenDayIncome;
          // console.log('this sevenday res is:')
          // console.log(res)
          // console.log('this sevenDayIncome is:')
          // console.log(this.sevenDayIncome)
          this.records1 =res.data.records.map(records => ({
            date: new Date(records.date).toLocaleDateString(), // 转换为日期字符串
            income: parseFloat(records.income), // 将 income 字符串转换为数字

          }))
          // console.log(' load4 this new records is:')
          // console.log(this.records1);
          // console.log('已经准备好数据，开始绘制表格：')
          this.renderChart();


        } else {
          // console.log(res)

        }
      })
    },
    load3() {

      this.$request.get('/udata/MoselectByUsername', {
        params: {
          username: this.user.username,
        }
      }).then(res => {
        if (res.data && res) {
          this.moIncome = res.data.MonthIncome;
          // console.log('this res is:')
          // console.log(res)
          // console.log('this moIncome is:')
          // console.log(this.moIncome)

        } else {
          // console.log(res)

        }
      })
    },
    load2() {

      this.$request.get('/udata/selectByUsername', {
        params: {
          username: this.user.username,
        }
      }).then(res => {
        if (res.data && res) {
          this.totalIncome = res.data.totalIncome;
          // console.log('this res is:')
          // console.log(res)
          // console.log('this totalIncome is:')
          // console.log(this.totalIncome)

        } else {
          console.log(res)

        }
      })
    },
    closeCard() {
      this.visible = false; // 关闭卡片
    },
  }


}
</script>

<style >


</style>
