

<template>

  <div class="article-container">
    <div class="article-title">{{ news.title }}</div>
    <div class="article-content" v-html="news.content"></div>
    <div class="back-button" @click="back">←返回帮助文档列表</div>
  </div>

</template>


<script >
import {Loading} from "element-ui";

export default {
  data(){
    return {
      news:{}
    }
  },
  mounted() {


    // console.log('this is a page');
    let id =this.$route.query.id;
    // console.log(id)
    this.load3()
  },
  methods:{
    showLoading() {
      this.loadingInstance = Loading.service({
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
    },

    // 隐藏加载弹窗
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },
    back(){
      if (this.$route.path !== '/specification') {
        this.$router.push('/specification');
      }
    },
    load3() {
      let unid =this.$route.query.id;
      this.showLoading(); // 显示加载弹窗
      this.$request.get('/public/selectByUnid', {
        params: {
          unid: unid,
        }
      }).then(res => {
        if (res.data && res) {

          /*console.log('this res is:')
          console.log(res)*/
          this.news = res.data
          // console.log('this totalIncome is:')
          // console.log(this.totalIncome)
          this.hideLoading(); // 隐藏加载弹窗
        } else {
          // console.log(res)
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err1 => {
        // console.error(err1); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
  }
}


</script>



<style scoped>

.article-container {
  max-width: 850px; /* 限制内容宽度 */
  //margin: 40px auto; /* 居中 */
  padding: 20px; /* 内边距 */
  background-color: #ffffff; /* 背景色 */
  border-radius: 10px; /* 圆角 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* 轻微阴影 */
  font-family: 'Arial', sans-serif; /* 字体样式 */
  line-height: 1.8; /* 行间距 */
  color: #333; /* 字体颜色 */
}

.article-title {
  font-size: 28px; /* 标题字体大小 */
  //margin: 40px auto; /* 居中 */
  font-weight: bold; /* 加粗标题 */
  color: #4a90e2; /* 标题颜色 */
  margin-bottom: 20px; /* 间距 */

  text-align: center;
  border-bottom: 2px solid #f0f0f0; /* 底部细线装饰 */
  padding-bottom: 10px; /* 增加底部内边距 */
}

.article-content {
  font-size: 16px; /* 内容字体大小 */
  color: #555; /* 内容颜色 */
}

.article-content img {
  max-width: 100%; /* 图片自适应宽度 */
  border-radius: 5px; /* 图片圆角 */
  margin: 20px 0; /* 图片上下间距 */
}

.article-content p {
  margin: 10px 0; /* 段落间距 */
}

.article-content a {
  color: #4a90e2; /* 链接颜色 */
  text-decoration: underline; /* 下划线 */
}

.article-content a:hover {
  color: #9013fe; /* 悬浮时颜色 */
  text-decoration: none; /* 移除下划线 */
}
.back-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #4a90e2;
  background-color: #f9f9f9;
  border: 2px solid #4a90e2;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.back-button:hover {
  background-color: #4a90e2;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.back-button:active {
  background-color: #3a78c2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>

